.accordions-faqs button.accordion-button,.accordions-faqs .accordion-button:not(.collapsed){
    background-color: #EEEDF7;
    box-shadow: none;
    color: #222222;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.accordions-faqs .accordion-item:first-of-type .accordion-button{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.accordions-faqs .accordion-body{
    background: #EEEDF7;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.accordions-faqs .accordion-button:focus{
    box-shadow: none;
}
.accordions-faqs .accordion-item{
    margin-bottom: 20px;
}
.accordions-faqs .accordion-button::after {
    background-image: url(/public/assets/img/faq/chevron_down.png);
}
.accordions-faqs .accordion-button:not(.collapsed)::after{
    background-image: url(/public/assets/img/faq/chevron_up.png);
    transform: rotate(0deg);
}
.accordions-faqs .accordion-item{
    border: 0px;
}
.accordions-faqs button.accordion-button.collapsed,.accordions-faqs .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 10px;
}
.accordions-faqs button.accordion-button {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}
.accordion-title h2 {
    max-width: 440px;
    margin: auto;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    background: -webkit-linear-gradient(rgba(66, 128, 216, 1), rgba(100, 26, 146, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.thumbs-reactions{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(84, 72, 178, 1);
}