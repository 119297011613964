.contact-seezitt .contact__wrapper {
    position: relative;
    width: 100%;
    min-height: auto;
    left: 0;
    top: auto;
    transform: translate(0, 0);
    border-radius: 25px;
}
.contact-form-wrapper .contact__info h2,.contact-seezitt .contact__form h2 {
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 35px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
}
.contact__info ul li{
    margin-bottom: 40px;
}
.contact__info ul li:last-child{
    margin-bottom: 0px;
}
.contact-form-wrapper .contact__info ul{
    text-align: left;
}
.contact-seezitt .contact__form {
    width: 100%;
    text-align: center;
}

section.contact__area.contact-seezitt {
    background-color: rgba(238, 237, 247, 1);
}

.vl {
    border-left: 1px solid rgba(233, 233, 233, 1);
    height: 100%;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

.contact-seezitt p,
.contact-seezitt p a {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(34, 34, 34, 1);
}

.contact-seezitt h4 {
    font-family: Poppins;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 30px;
    color: rgba(84, 72, 178, 1);
}
.contact-seezitt .contact__form input.form-control,
.contact-seezitt .contact__form textarea
 {
    background: #fff;
    border: 1px solid rgba(209, 209, 209, 1);
    height: 48px;
    color: rgba(209, 209, 209, 1);
    border-radius: 6px;
    font-size: 16px;
}
.contact-seezitt .contact__form input.form-control:focus,
.contact-seezitt .contact__form textarea:focus{
    color: rgba(34, 34, 34, 1);
    box-shadow:none !important;
}

.contact-seezitt .contact__form textarea{
    height: 117px;
}
.contact-seezitt .contact__form button{
    background: rgba(84, 72, 178, 1);
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    padding: 11px;
}
.contact-form-wrapper {
    background: rgba(255, 255, 255, 1);
    padding: 40px 62px;
    border-radius: 20px;
    position: relative;
    margin: 15px 0px !important;
}
.contact-form-wrapper .contact__info {
    max-width: 488px;
}
.form-control::placeholder {
    color: #666 !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
@media (max-width:1199px) {
    .contact-form-wrapper .pl-60{
        padding: 0px;
    }
}