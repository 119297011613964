.testimonial__content {
    position: relative;
    padding-bottom: 0px !important;
}

.testimonial__content .testimonial__item img {
    border-radius: 15px;
}

.testimonial__content .testimonial__item {
    display: flex !important;
    justify-content: center;
}

.testimonial__info-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: -webkit-fill-available;
}

.testimonial__info-overlay h4 {
    color: #fff;
    margin: 0;
    padding: 20px;
}

.testimonial__content .testimonial__info-overlay h4 {
    color: #fff;
    margin: 0;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
}

.testimonial__slider .slick-slide[data-index="0"] {
    height: 470px !important;
}

.testimonial__content .testimonial__slider .slick-dots li.slick-active {
    background: rgba(84, 72, 178, 1);
    border-color: rgba(84, 72, 178, 1);
}

.testimonial__content .testimonial__slider .slick-dots li {
    background: rgba(223, 223, 223, 1);
    border: 2px solid rgba(223, 223, 223, 1);
    margin-right: 5px;
    width: 16px;
    height: 16px;
}

.testimonial__content .slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.09);
    transition: transform 0.3s ease;
    transform-origin: center top;
    /* Set the transformation origin to the top center */
}

.testimonial__content .testimonial__slider .slick-dots {
    position: relative;
    margin-top: 40px;
}

.testimonial__content .testimonial__item {
    position: relative;
}

.testimonial__content button {
    padding: 10px 15px;
    background-color: rgba(84, 72, 178, 1);
    color: #fff;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: inherit;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.image-wrapper {
    position: relative;
}

.image-wrapper::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Change the color and opacity as needed */
    border-radius: 15px;
}

.image-wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
}

.browse-image-hover-inner {
    opacity: 1 !important;
    visibility: visible !important;
}
.testimonial__item.slick-current .image-wrapper::before {
    content: none !important;
}

.testimonial__item:hover .image-wrapper::before {
    content: none !important;
}