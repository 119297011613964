@media (max-width:1199px) {
    .contact-form-wrapper .contact__info{
        max-width: 100%;
    }
}
@media (max-width:991px) {
    .achievement__item{
        padding-right: 0px;
    }
    .accordion-title h2,.achievement__area.home-download h3{
        font-size: 36px;
        line-height: initial;
    }
}
