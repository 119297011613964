.card-title.h5 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.person-name p,.play-icon p,.slide-number p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(34, 34, 34, 1);
    margin-bottom: 0px;
}
.play-icon p{
    color: rgba(107, 107, 107, 1);
}
.personal-info,.play-icon {
    display: flex;
    align-items: baseline;
}
.play-icon{
    align-items: center;
}
.person-avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}
.play-icon img{
    margin-right: 5px;
}
.slide-number p{
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.browse-main-title p{
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    color: rgba(107, 107, 107, 1);
}
@media (min-width: 1200px) {
    .browse-detail .col-xl-20 {
        width: 20%;
        float: left;
    }
}
@media (max-width:991px) {
    .browse-main-title p{
        font-size: 20px;
        line-height: initial;
    }
}