.work-bg {
    background-color: rgba(238, 237, 247, 1);
}

.work-bg img.card-img-left {
    background: rgba(238, 237, 247, 1);
    border-radius: 4px;
    width: 60px;
    padding: 10px;
    height: max-content;
}

.work-bg h5.card-title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: rgba(34, 34, 34, 1);
}

.work-bg .card-body p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(107, 107, 107, 1);
}
.work-bg .card{
    padding: 30px;
    border-radius: 12px;
}
.card-image-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
@media (max-width:991px) {
    .work-bg .card-body p {
        font-size: 16px;
        line-height: 30px;
    }
}