.footer__widget-content p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}
.footer__widget-content ul li a{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
}