.vision-bg{
    background-color: rgba(238, 237, 247, 1);
}
.vision-para p{
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(107, 107, 107, 1);
}