section.page__title::before {
    background-image: linear-gradient(60deg, #000000CC 0%, #000000CC 100%, #000000CC, #000000CC);
}

section.page__title h2 {
    font-family: Poppins;
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
}

section.page__title p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}
section .page__title-content{
    max-width: 568px;
    margin: auto;
}
@media (max-width:991px) {
    section.page__title h2{
        font-size: 30px;
        line-height: initial;
    }
}