.download-section ul li a {
    background: transparent;
    border: 0px !important;
    padding: 0px;
}
.download-section ul {
    display: flex;
}
.download-section ul li {
    margin-right: 10px;
}
.achievement__area.home-download h3 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    background: -webkit-linear-gradient(#4280d8, #641a92);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.achievement__area.home-download p{
    color: rgba(34, 34, 34, 1);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}
@media (max-width:500px) {
    .download-section ul{
        display:block;
    }
    .download-section ul li{
        margin-bottom: 10px;
    }
}