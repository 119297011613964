.browse-image img {
    width: 100%;
}

.image-wrapper {
    position: relative;
}

.image-wrapper::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.image-wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}

.browse-image h4 {
    color: #fff;
    margin: 0;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    padding: 25px 16px;
}

.browse-image-hover img {
    width: min-content;
}

.browse-image-hover {
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
}

.browse-image-hover a{
    background: transparent;
    height: min-content;
    padding: 0px;
    border-radius: 0px;
    border: none !important;
}

.browse-image-hover-inner {
    display: flex;
    justify-content: space-between;
    background: rgba(84, 72, 178, 1);
    padding: 10px 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: 64px;
    align-items: center;
}

.browse-image-hover-inner button {
    background: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.browse-image-hover-inner img {
    height: max-content;
}

.browse-image-hover-inner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Add transition effect for opacity and visibility */
}

.browse-image:hover .browse-image-hover-inner {
  opacity: 1;
  visibility: visible;
}

.browse-image:hover::before {
  content: none !important;
}
@media (max-width:991px){
    .browse-image h4 {
        font-size: 24px;
    }
    .browse-image-hover-inner{
        min-height: auto;
    }
}
@media (max-width:767px){
    .browse-image h4 {
        font-size: 20px;
    }
}
@media (max-width:576px){
    .browse-image h4 {
        font-size: 16px;
    }
}
