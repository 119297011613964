.achievement__area a {
    background: linear-gradient(90deg, #4280D8 0%, #641A92 118.05%);
    border-radius: 50px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 8px 20px;
    height: 43px;
    line-height: inherit;
    border:1px solid rgba(84, 72, 178, 1) !important;
}
.achievement__area.home-about h3{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    color: rgba(34, 34, 34, 1);
}
.achievement__area.home-about p{
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    color: rgba(153, 153, 153, 1);
}
@media (max-width:991px) {
    .achievement__area.home-about h3,.achievement__area.home-about p,.vision-para p{
        font-size: 20px;
        font-weight: 500;
        line-height: initial;
    }
}