.header__btn a,
.header__bottom.sticky .header__btn a {
    background: rgba(84, 72, 178, 1) !important;
    color: #fff !important;
    border-color: rgba(84, 72, 178, 1) !important;
}

.header__btn a:hover,
.header__bottom.sticky .header__btn a:hover {
    color: rgba(84, 72, 178, 1) !important;
    background: transparent !important;
    border-color: rgba(84, 72, 178, 1) !important;
}

.main-menu.main-menu-dropdown ul li ul.submenu {
    border-top: 0px;
    min-width: 170px;
    text-align: center;
    border-radius: 6px;
    padding: 16px;
}

.main-menu.main-menu-dropdown ul li ul.submenu li {
    display: block;
    margin-right: 0;
    padding: 6px 25px 6px 25px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    margin: auto;
}

.main-menu.main-menu-dropdown ul li ul.submenu li:last-child {
    border-bottom: none;
}

.main-menu.main-menu-dropdown ul li ul.submenu li a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(34, 34, 34, 1);
}

.main-menu.main-menu-dropdown ul li ul.submenu li:hover>a {
    color: rgba(34, 34, 34, 1);
}

.main-menu.main-menu-dropdown ul li ul.submenu li a::before {
    display: none;
}

.main-menu.main-menu-dropdown ul li a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 10px;
}

.menu_wrapper_one.main-menu-dropdown a.active{
    border-bottom: 2px solid rgba(84, 72, 178, 1);
    color: rgba(84, 72, 178, 1);
    opacity: 1;
}
.main-menu.main-menu-dropdown ul li ul.submenu li a.active{
    border-bottom: 0px solid rgba(84, 72, 178, 1);
}
.header__transparent{
    top: 30px !important;
}
.header__transparent .sidebar-toggle-btn .line{
    background-color: rgba(84, 72, 178, 1);
}
@media (max-width:991px){
    a.download-now-btn {
        display: none;
    }
}